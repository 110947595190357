/**
 * @generated SignedSource<<f8c9a1aa713c7ed11cff2416e60c4065>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumParkingParkingInvoicePaymentDetailsFocReason = "CUSTOMER_ISSUE" | "EMPLOYEE_EXIT" | "FREE_PARKING_TICKET" | "MISSED_EXIT" | "OTHER" | "VALET_EXIT" | "VIP_EXIT" | "%future added value";
export type EnumParkingParkingInvoicePaymentDetailsMode = "APP" | "CASH" | "FASTAG" | "FOC" | "ONLINE" | "%future added value";
export type EnumParkingStatus = "FAULTY_PARK" | "FAULTY_RECALL" | "INCOMING" | "MISSED_EXIT" | "OUT" | "OUTGOING" | "PARKED" | "%future added value";
export type addParkingRecordInput = {
  campus: ParkingCampusInput;
  feedback?: ParkingFeedbackInput | null;
  journey: ReadonlyArray<ParkingJourneyInput | null>;
  ownerId: any;
  parked_at?: any | null;
  parkingInvoice?: ParkingParkingInvoiceInput | null;
  parkingTag?: string | null;
  status: EnumParkingStatus;
  trakingParkingIds?: ReadonlyArray<string | null> | null;
  vehicle: ParkingVehicleInput;
  vehicleId: any;
};
export type ParkingVehicleInput = {
  brand?: ParkingVehicleBrandInput | null;
  color?: string | null;
  registration: ParkingVehicleRegistrationInput;
};
export type ParkingVehicleBrandInput = {
  _id?: any | null;
  brandLogo?: string | null;
  name: string;
};
export type ParkingVehicleRegistrationInput = {
  plate: string;
};
export type ParkingFeedbackInput = {
  _id?: any | null;
  description?: string | null;
  feedbackFor?: ReadonlyArray<string | null> | null;
  rating: number;
};
export type ParkingJourneyInput = {
  taskID: any;
};
export type ParkingCampusInput = {
  _id: any;
};
export type ParkingParkingInvoiceInput = {
  _id?: any | null;
  charges?: ParkingParkingInvoiceChargesInput | null;
  invoicePdfPath?: string | null;
  paymentDetails?: ParkingParkingInvoicePaymentDetailsInput | null;
  timings?: ParkingParkingInvoiceTimingsInput | null;
};
export type ParkingParkingInvoiceTimingsInput = {
  _id?: any | null;
  chargeTime?: number | null;
  entryTime: any;
  exitTime?: any | null;
};
export type ParkingParkingInvoicePaymentDetailsInput = {
  _id?: any | null;
  amount?: number | null;
  focReason?: EnumParkingParkingInvoicePaymentDetailsFocReason | null;
  isPaid: boolean;
  message?: string | null;
  mode?: EnumParkingParkingInvoicePaymentDetailsMode | null;
};
export type ParkingParkingInvoiceChargesInput = {
  _id?: any | null;
  cashierDiscountAmount?: number | null;
  chargesBreakup?: ReadonlyArray<ParkingParkingInvoiceChargesChargesBreakupInput | null> | null;
  discountAmount?: number | null;
  totalPayable?: number | null;
  valetTip?: number | null;
};
export type ParkingParkingInvoiceChargesChargesBreakupInput = {
  _id?: any | null;
  discountAmount?: number | null;
  duration?: number | null;
  payable?: number | null;
};
export type parkingApiAddMutation$variables = {
  record: addParkingRecordInput;
};
export type parkingApiAddMutation$data = {
  readonly addParking: {
    readonly record: {
      readonly _id: any;
      readonly journey: ReadonlyArray<{
        readonly taskID: any;
      } | null> | null;
      readonly owner: {
        readonly name: {
          readonly first: string;
          readonly last: string | null;
        } | null;
      } | null;
      readonly ownerId: any;
      readonly status: EnumParkingStatus;
      readonly vehicle: {
        readonly brand: {
          readonly brandLogo: string | null;
          readonly name: string;
        } | null;
        readonly color: string | null;
        readonly registration: {
          readonly plate: string;
        };
      };
      readonly vehicleId: any;
    } | null;
  } | null;
};
export type parkingApiAddMutation = {
  response: parkingApiAddMutation$data;
  variables: parkingApiAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOneParkingPayload",
    "kind": "LinkedField",
    "name": "addParking",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Parking",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingVehicle",
            "kind": "LinkedField",
            "name": "vehicle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingVehicleBrand",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandLogo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ParkingVehicleRegistration",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "plate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserName",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ParkingJourney",
            "kind": "LinkedField",
            "name": "journey",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taskID",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "parkingApiAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "parkingApiAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "88fd21b384346f8cfc8e898410751f55",
    "id": null,
    "metadata": {},
    "name": "parkingApiAddMutation",
    "operationKind": "mutation",
    "text": "mutation parkingApiAddMutation(\n  $record: addParkingRecordInput!\n) {\n  addParking(record: $record) {\n    record {\n      _id\n      vehicle {\n        brand {\n          name\n          brandLogo\n        }\n        color\n        registration {\n          plate\n        }\n      }\n      owner {\n        name {\n          first\n          last\n        }\n      }\n      status\n      journey {\n        taskID\n      }\n      ownerId\n      vehicleId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a6f656e2fd65fdf60be90fce175e861";

export default node;
